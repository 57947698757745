import React, { useEffect, useState } from 'react'
import StepThreeHeader from './StepThreeHeader'
import { decrypt, encrypt, endSession, getUserData, isLogin, isSessionActive, parseString } from '../../../helpers/functions'
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, message, Modal, Radio } from 'antd';
import QuestionExplanation from '../Question/QuestionExplanation';
import magicPen from '../../../assets/images/magicPen.png';
import downloadImg from '../../../assets/images/Download.png';
import { useDispatch, useSelector } from 'react-redux';
import { FLASH_CARD_INSTRUCTIONS_WITH_KEYWORDS, FLASH_CARD_INSTRUCTIONS_WITHOUT_KEYWORDS } from '../../../helpers/gptConstant';




export default function SessionStepThree() {

    const dispatch = useDispatch();
    const { sessionCategoryData, sessionCategoryDataError, userFlagedQuestionList, userFlashedData } = useSelector(state => state.SessionReducer);

    const { gptResponse, gptResponseError } = useSelector(state => state.CommonReducer);

    const navigate = useNavigate();
    const location = useLocation();
    const SessionRec = location.state;
    const [userChoice, setUserChoice] = useState("");
    const [answerVisible, setAnswerVisible] = useState(false);
    const [flashCardModelVisible, setFlashCardModelVisible] = useState(false);
    const [freezeQuestion, setFreezeQuestion] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(setCurrentQuestionIndexMethod());
    const [cuurentQuestion, setCurrentQuestion] = useState({});
    const [flageQuestion, setFlageQuestion] = useState(false);
    const [currentTime, setCurrentTime] = useState(sessionCategoryData?.length * 120);
    const [flahsCardLoading, setFlahsCardLoading] = useState(false);
    const [flashCardData, setFlashCardData] = useState({});
    const [flashCardCurrentIndex, setFlashCardCurrentIndex] = useState(0);
    const [flashcardKeywordBtnVisible, setFlashcardKeywordBtnVisible] = useState(true);
    const [isQuestionFlashed, setIsQuestionFlashed] = useState(isQuestionFlashedMethod())

    function setCurrentQuestionIndexMethod() {

        if (SessionRec?.type == "RESUME") {
            let userSessionRec = localStorage.getItem("userSessionRec") || [];
            if (userSessionRec.length > 0) {
                userSessionRec = JSON.parse(decrypt(userSessionRec));
            }
            return userSessionRec?.length;
        } else {
            return 0;
        }
    }

    const handleUserChoice = (e) => {
        setUserChoice(e.target.value);
    };

    const handleExitSession = () => {
        saveUserSessionData();
        endSession();
        navigate("/previous-sessions");
    }

    const handleCheckAnswer = () => {
        if (!saveUserChoice()) return;
        setFreezeQuestion(true)
        setAnswerVisible(true)
    }
    const handleNextQuestionClick = () => {
        if (currentQuestionIndex == sessionCategoryData.length - 1) return;
        if (!saveUserChoice()) return;
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setFreezeQuestion(false)
        setAnswerVisible(false)
        setUserChoice("");
        setFlageQuestion(false)
    }
    const handlePreviousQuestionClick = () => {
        if (currentQuestionIndex == 0) return;
        setCurrentQuestionIndex(currentQuestionIndex - 1);
    }

    const saveUserChoice = () => {
        if (userChoice === "") {
            message.error("Please select an option");
            return false;
        }
        let userSessionRec = localStorage.getItem("userSessionRec") || [];
        if (userSessionRec.length > 0) {
            userSessionRec = JSON.parse(decrypt(userSessionRec));
        }

        if (isQuestionAnswered() !== undefined) {
            let index = userSessionRec.findIndex(item => item?.question_path === cuurentQuestion?.linkDestination);
            userSessionRec[index] = {
                flag: flageQuestion,
                isCorrect: (userChoice == ((cuurentQuestion?.questionChoices || [])?.findIndex(item => item?.isCorrect === true)) || false),
                mode: SessionRec?.stepData?.mode,
                question_path: cuurentQuestion?.linkDestination,
                selected_choice: userChoice,
                sequence_of_attempt: currentQuestionIndex + 1,
                timer: currentTime,
            }
            localStorage.setItem("userSessionRec", encrypt(JSON.stringify(userSessionRec)));
            return true;
        }
        userSessionRec = [...userSessionRec, {
            flag: flageQuestion,
            isCorrect: (userChoice == ((cuurentQuestion?.questionChoices || [])?.findIndex(item => item?.isCorrect === true)) || false),
            mode: SessionRec?.stepData?.mode,
            question_path: cuurentQuestion?.linkDestination,
            selected_choice: userChoice,
            sequence_of_attempt: currentQuestionIndex + 1,
            timer: currentTime,
        }];
        localStorage.setItem("userSessionRec", encrypt(JSON.stringify(userSessionRec)));
        return true;
    }

    const handleChoiceColor = (choice) => {
        if (freezeQuestion && SessionRec?.stepData?.mode == 1) {
            if (choice == (cuurentQuestion?.questionChoices || [])?.findIndex(item => item?.isCorrect === true) || "") {
                return "green"
            } else {
                return "red"
            }
        }
    }

    useEffect(() => {

        if (sessionCategoryData && sessionCategoryData.length > 0 && sessionCategoryDataError === false && isSessionActive()) {

            setCurrentQuestion(sessionCategoryData?.[currentQuestionIndex]);

        } else {
            endSession();
            navigate("/");
        }

    }, [sessionCategoryData]);


    useEffect(() => {
        if (sessionCategoryData && sessionCategoryData.length > 0 &&
            sessionCategoryDataError === false &&
            sessionCategoryData?.[currentQuestionIndex]) {
            setCurrentQuestion(sessionCategoryData?.[currentQuestionIndex]);

        }

    }, [currentQuestionIndex]);


    useEffect(() => {
        if (isQuestionAnswered() !== undefined && cuurentQuestion && cuurentQuestion?.questionChoices &&
            cuurentQuestion?.questionChoices?.length > 0) {
            setFreezeQuestion(true)
            setAnswerVisible(true)
            setUserChoice(isQuestionAnswered()?.selected_choice)
            setFlageQuestion(isQuestionAnswered()?.flag || false)
            setFlashCardData(cuurentQuestion?.flash_data || {})
            setFlashcardKeywordBtnVisible(true)
            setIsQuestionFlashed(isQuestionFlashedMethod());
        }

    }, [cuurentQuestion]);

    useEffect(() => {

        if (gptResponse != null && gptResponse?.length > 0) {
            if (gptResponse?.[0]?.front) {
                if (flashcardKeywordBtnVisible) {
                    dispatch({ type: "SET_DATA_TO_DATABASE", path: cuurentQuestion?.linkDestination + "/flash_data", data: gptResponse });
                }
                dispatch({ type: "CLEAR_STATE", name: "gptResponse", value: "" });
                setFlashCardData(gptResponse);
                setFlashCardModelVisible(true);
                setFlashCardCurrentIndex(0);
            }
        }
        setFlahsCardLoading(false);
    }, [gptResponse, gptResponseError]);


    useEffect(() => {
        if (userFlashedData && userFlashedData?.length > 0) {
            localStorage.setItem("flashCardData", encrypt(JSON.stringify(userFlashedData)));
        }
    }, [userFlashedData]);
    function correctAnswer(value) {
        if (cuurentQuestion?.questionChoices && cuurentQuestion?.questionChoices?.length > 0) {
            return cuurentQuestion?.questionChoices?.filter(item => item?.isCorrect === true)?.[0]?.[value] || "N/A"
        } else {
            return "N/A"
        }
    }

    function isQuestionAnswered() {
        let userSessionRec = localStorage.getItem("userSessionRec") || [];
        if (userSessionRec.length > 0) {
            userSessionRec = JSON.parse(decrypt(userSessionRec));
        }
        return userSessionRec?.find(item => item?.question_path === cuurentQuestion?.linkDestination)
    }

    const saveSession = () => {
        if (!saveUserChoice()) return;
        if (currentQuestionIndex !== sessionCategoryData.length - 1) return;
        setFreezeQuestion(true)
        setAnswerVisible(true)
        let userSessionRec = localStorage.getItem("userSessionRec") || [];
        if (userSessionRec.length > 0) {
            userSessionRec = JSON.parse(decrypt(userSessionRec));
            if (userSessionRec?.length !== sessionCategoryData.length) return message.error("Please answer all questions");
            saveUserSessionData();
            endSession();
            navigate("/previous-sessions");
        }
    }

    const saveUserSessionData = (type) => {

        let userSessionRec = localStorage.getItem("userSessionRec");
        if (userSessionRec && userSessionRec.length > 0 && SessionRec?.sessionLinkDestinations) {
            let path = SessionRec?.sessionLinkDestinations + "/answers";
            userSessionRec = JSON.parse(decrypt(userSessionRec));
            // saved flaged question 
            let flagedQuestions = userSessionRec?.filter(item => item?.flag === true)?.map(item => item?.question_path);

            flagedQuestions = [...new Set([...userFlagedQuestionList, ...flagedQuestions])];

            let flashedData = localStorage.getItem("flashCardData");

            if (flashedData && flashedData.length > 0) {
                flashedData = JSON.parse(decrypt(flashedData));

                dispatch({ type: "SET_DATA_TO_DATABASE", path: "/userinfo/" + getUserData()?.uid + "/flashed_data", data: flashedData });
            }

            dispatch({ type: "SET_DATA_TO_DATABASE", path: path, data: userSessionRec });
            dispatch({ type: "SET_DATA_TO_DATABASE", path: "/userinfo/" + getUserData()?.uid + "/flagged_questions", data: flagedQuestions });
        }
        if (type == "refresh") return;
        dispatch({ type: "CLEAR_STATE", name: "sessionCategoryData", value: [] });
        dispatch({ type: "CLEAR_STATE", name: "userPreviousSessionList", value: [] });
    }


    const onTimerEnd = () => {
        saveUserSessionData();
        endSession();
        navigate("/previous-sessions");

    }
    // Listen for beforeunload events
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            saveUserSessionData("refresh");
            event.returnValue = ""; // Modern browsers ignore custom messages, this line ensures the prompt shows
        };

        // Attach the event listener
        window.addEventListener("beforeunload", handleBeforeUnload);

        // Cleanup on unmount
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);


    // genrate propmt for flash card 
    function genrateQuestionFormat(Question, type) {
        return `${parseString(Question?.Qbody)}
        Choices:
        ${Question?.questionChoices?.map(item => item?.choice).join("\n")}
        Answer:
        ${correctAnswer("choice")}
        Explanation:
        ${parseString(Question?.QExplanation)}
        ${type == "flashCardWithKeyword" && "Keyword: " + parseString(Question?.keywords)}`
    }
    const handleFalshCardClick = () => {
        if (SessionRec?.stepData?.mode != 1) return;
        if (Object.keys(flashCardData).length == 0) {
            setFlahsCardLoading(true);
            dispatch({
                type: "GET_GPT_ASSITENT_RESPONSE", data: {
                    threadId: "",
                    prompt: genrateQuestionFormat(cuurentQuestion, "flashCardWithoutKeyword"),
                    instructions: FLASH_CARD_INSTRUCTIONS_WITHOUT_KEYWORDS
                }
            });
        } else {
            setFlashCardModelVisible(true);
        }
    }

    /**
     * Handles the click event for the flashcard with keyword based button.
     * If the mode is not set to 1, the function does nothing.
     * If the flashcardKeywordBtnVisible state is true, it sets the state to false 
     * and dispatches an action to get the GPT assitent response with the 
     * prompt generated by the genrateQuestionFormat function and the instructions
     * set to FLASH_CARD_INSTRUCTIONS_WITH_KEYWORDS.
     */

    const handleflashCardKeywordBasedClick = () => {
        if (SessionRec?.stepData?.mode != 1) return;
        if (flashcardKeywordBtnVisible) {
            setFlashcardKeywordBtnVisible(false);
            setFlahsCardLoading(true);
            dispatch({
                type: "GET_GPT_ASSITENT_RESPONSE", data: {
                    threadId: "",
                    prompt: genrateQuestionFormat(cuurentQuestion, "flashCardWithKeyword"),
                    instructions: FLASH_CARD_INSTRUCTIONS_WITH_KEYWORDS
                }
            });
        }
    }

    const handleAddtoFlashCardClick = () => {
        if (SessionRec?.stepData?.mode != 1) return;

        let userFlashCardData = localStorage.getItem("flashCardData") || [];

        if (userFlashCardData && userFlashCardData.length > 0) {

            userFlashCardData = JSON.parse(decrypt(userFlashCardData));

        }

        if (!isQuestionFlashed) {

            userFlashCardData = [...userFlashCardData,
            {
                ...flashCardData?.[flashCardCurrentIndex],
                question_path: cuurentQuestion?.linkDestination,
                category: getCategoryName()

            }
            ];

            setIsQuestionFlashed(true);

        } else {

            userFlashCardData = userFlashCardData?.filter(item => item?.question_path !== cuurentQuestion?.linkDestination);
            setIsQuestionFlashed(false);

        }
        console.log(userFlashCardData);

        localStorage.setItem("flashCardData", encrypt(JSON.stringify(userFlashCardData)));

    }

    function isQuestionFlashedMethod() {
        let userFlashCardData = localStorage.getItem("flashCardData") || [];
        if (userFlashCardData.length > 0) {
            userFlashCardData = JSON.parse(decrypt(userFlashCardData)) || [];
            return userFlashCardData?.find(item => item?.question_path === cuurentQuestion?.linkDestination) ? true : false;
        } else {
            return false
        }
    }

    function getCategoryName() {
        if (SessionRec && SessionRec?.category?.length > 0) {
            let category = SessionRec?.category?.find(obj => cuurentQuestion?.linkDestination.includes(obj.key));
            return category?.name || "No Category";
        }
        return "No Category";
    }

    return (
        <>
            <StepThreeHeader
                handleExitSession={handleExitSession}
                currentQuestionIndex={currentQuestionIndex}
                tottalQuestions={sessionCategoryData?.length}
                flageQuestion={flageQuestion}
                setFlageQuestion={setFlageQuestion}
                SessionRec={SessionRec}
                onTimerEnd={onTimerEnd}
                setCurrentTime={setCurrentTime}
            />
            <div className='container my-5 bg-white p-4'>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div>
                        <Button
                            htmlType="button"
                            size="large"
                            type="primary"
                            onClick={handlePreviousQuestionClick}
                            disabled={(currentQuestionIndex === 0) || flahsCardLoading}
                        >
                            <i className='simple-icon-arrow-left-circle'></i>
                            Pevious
                        </Button>
                    </div>
                    <div>
                        {
                            (currentQuestionIndex === sessionCategoryData.length - 1)

                                ?
                                <Button
                                    htmlType="button"
                                    size="large"
                                    type="primary"
                                    disabled={currentQuestionIndex !== sessionCategoryData.length - 1}
                                    onClick={saveSession}
                                >
                                    Finish
                                    <i className='simple-icon-arrow-right-circle'></i>
                                </Button>

                                :
                                <Button
                                    htmlType="button"
                                    size="large"
                                    type="primary"
                                    disabled={(currentQuestionIndex === sessionCategoryData.length - 1) || flahsCardLoading}
                                    onClick={handleNextQuestionClick}
                                >
                                    Next
                                    <i className='simple-icon-arrow-right-circle'></i>
                                </Button>
                        }

                    </div>
                </div>
                {/* question div here   */}
                <div className='font-weight-bold mb-3' style={{ fontSize: '1rem' }}>
                    <p dangerouslySetInnerHTML={{ __html: cuurentQuestion?.Qbody || "Question" }}>

                    </p>
                </div>
                {/* question div end here   */}

                {/* choice div here  */}
                <div className='mb-3'>
                    {cuurentQuestion?.questionChoices && cuurentQuestion?.questionChoices?.length > 0 && cuurentQuestion?.questionChoices.map((item, index) => (
                        <div className='font-weight-bold' key={index} style={{ fontSize: '1.3rem' }}>
                            <Radio.Group onChange={handleUserChoice} value={userChoice}>
                                <Radio value={index} disabled={freezeQuestion}>
                                    <span style={{
                                        color: handleChoiceColor(index),
                                    }} >

                                        {item?.choice}
                                    </span>
                                </Radio>
                            </Radio.Group>
                        </div>
                    ))}
                </div>

                {/* choice div end here */}

                {
                    answerVisible && SessionRec?.stepData?.mode == 1 &&
                    <>
                        {/* answer div here */}

                        < div className='mb-3'>
                            <div className='font-weight-bold text-muted' style={{ fontSize: '1rem' }}>Your Answer: {cuurentQuestion?.questionChoices?.[userChoice]?.choice}</div>
                            <div className='font-weight-bold text-muted' style={{ fontSize: '1rem' }}>Correct Answer: {correctAnswer("choice")}</div>
                        </div>
                        <div className='mb-4'>
                            <div className='font-weight-bold text-muted' style={{ fontSize: '1rem' }}>Difficulty: {cuurentQuestion?.modeOfDifficulty}</div>
                        </div>
                        {/* answer div end here */}

                        {/* expatnation div here */}
                        <QuestionExplanation explanation={cuurentQuestion?.QExplanation || ""} />
                    </>
                }

                {/* expatnation div end here */}

                {/* button div here */}
                {
                    (SessionRec?.stepData?.mode == 1) &&
                    <div className=' d-flex justify-content-start'>
                        {freezeQuestion &&
                            <>
                                <Button type="primary" htmlType="button" size="large" onClick={() => setFlageQuestion(!flageQuestion)} >

                                    {(flageQuestion) ? "Remove from Library" : "Save to Library"}

                                </Button>

                                <Button
                                    htmlType="button"
                                    className='ml-3'
                                    size="large"
                                    loading={flahsCardLoading}
                                    style={{ background: "#FFAD00" }}
                                    onClick={() => (handleFalshCardClick())}
                                >
                                    Quick Flashcards
                                </Button>
                            </>
                        }
                        <Button htmlType="button" type="primary" className='ml-3' disabled={answerVisible} size="large" onClick={() => handleCheckAnswer()}>
                            Check Answer
                        </Button>
                    </div>
                }
            </div >

            {/* flashCard Model */}
            <Modal
                title={<FlashCardHeader
                    flashCardCurrentIndex={flashCardCurrentIndex}
                    setFlashCardCurrentIndex={setFlashCardCurrentIndex}
                    flashCardData={flashCardData}
                />}
                open={flashCardModelVisible}
                onCancel={() => (setFlashCardModelVisible(false), setFlashCardCurrentIndex(0))}
                footer={null}
                width={900}

            >
                <div className="row mt-3">
                    <div className="col-md-6">
                        <div>
                            <h5 >Front</h5>
                        </div>
                        <div className='card p-3 d-flex justify-content-center' style={{ height: "332px" }}>
                            <p className='text-center font-weight-bold'>
                                {flashCardData?.[flashCardCurrentIndex]?.front || ""}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div>
                            <h5 >Back</h5>
                        </div>
                        <div className='card p-3 d-flex justify-content-center' style={{ height: "332px" }}>
                            <p className='text-center font-weight-bold'>{flashCardData?.[flashCardCurrentIndex]?.back || ""}</p>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <Button htmlType="button"
                            className='mb-3'
                            size="large"
                            style={{ background: "#FFAD00" }}
                            loading={flahsCardLoading}
                            onClick={() => handleAddtoFlashCardClick()}>

                            <img src={downloadImg} alt="download" />
                            {
                                (isQuestionFlashed) ? "Remove from FlashCards" : "Add to FlashCards"
                            }

                        </Button>
                    </div>
                    <div className="col-md-6 text-center">
                        <Button
                            htmlType="button"
                            className='mb-3'
                            size="large"
                            type="primary"
                            disabled={!flashcardKeywordBtnVisible}
                            loading={flahsCardLoading}
                            onClick={() => handleflashCardKeywordBasedClick()}>

                            <img src={magicPen} alt="magic pen" />  Generate Based on Keywords

                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}


function FlashCardHeader({ flashCardData, flashCardCurrentIndex, setFlashCardCurrentIndex }) {
    return (
        <>
            <div className='d-flex justify-content-between align-items-center w-50 m-auto'>
                <div>
                    <Button
                        onClick={() => setFlashCardCurrentIndex(flashCardCurrentIndex - 1)}
                        disabled={flashCardCurrentIndex == 0}
                    >
                        <i className='simple-icon-arrow-left-circle'></i>
                    </Button>
                </div>
                <div> <h2>Quick Flash Card {flashCardCurrentIndex + 1} of {flashCardData?.length}</h2></div>
                <div>
                    <Button
                        onClick={() => setFlashCardCurrentIndex(flashCardCurrentIndex + 1)}
                        disabled={flashCardCurrentIndex == flashCardData?.length - 1}
                    >
                        <i className='simple-icon-arrow-right-circle'></i>
                    </Button></div>
            </div>
        </>
    )
}
