import React, { useEffect, useState } from 'react'
import SessionListHeader from './SessionListHeader';
import { decrypt, encrypt, getUserData, startSession } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Radio, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import QuestionExplanation from './Question/QuestionExplanation';

export default function SessionList() {
    const {
        userPreviousSessionList,
        userPreviousSessionListError,
        reviewSessionData,
        reviewSessionDataError,
        sessionCategoryData,
        sessionCategoryDataError } = useSelector(state => state.SessionReducer);

    const dispatch = useDispatch();
    const navigation = useNavigate();

    const [openReviewModal, setOpenReviewModal] = useState(false);
    const [sessionIndexId, setSessionIndexId] = useState(0);
    const [reviewSessionRecord, setReviewSessionRecord] = useState({});
    const [isSessionLoading, setIsSessionLoading] = useState("");
    const [cuurentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [isResumeSessionLoading, setIsResumeSessionLoading] = useState(false);
    const [resumeSessionrecord, setResumeSessionrecord] = useState({});

    useEffect(() => {
        if (userPreviousSessionList && userPreviousSessionList.length == 0 && userPreviousSessionListError == false) {
            dispatch({ type: "USER_PREVIOUS_SESSION", userId: getUserData().uid });
        }
    }, []);

    const getcategoryName = (category) => {
        if (category && category.length == 0) return "N/A";
        return category?.map(c => c?.name).join(', ');
    }

    function getModeName(mode) {
        switch (mode) {
            case 1:
                return "Tutor Mode - Untimed";
            case 2:
                return "Test Mode - Untimed";
            case 3:
                return "Test Mode - Timed";
            default:
                return "Tutor Mode - Untimed";
        }
    }
    /**
     * Handles the click event of the button for each session.
     * If all the questions of the session are answered then it calls reviewQuestion.
     * @param {Object} item The item of the session list.
     */
    const handleBtnClick = (item) => {
        if (((item?.answers?.length || 0) == (item?.no_of_questions || 0))) {
            reviewQuestion(item);
        } else {
            let userSessionRec = {
                no_of_questions: item?.no_of_questions || 0,
                category: item?.category || [],
                timestamp: item?.timestamp || new Date().toLocaleString("en-US", {
                    timeZone: "America/Chicago",
                }),
                sessionLinkDestinations: item?.sessionLinkDestinations || "",
                stepData: {
                    mode: item?.answers?.[0]?.mode || 1,
                    mode_name: getModeName(item?.answers?.[0]?.mode)
                }
            }
            let categoryRec = item?.category?.map(cat => ({
                key: cat?.key,
                title: cat?.name,
                totalQuestion: parseInt(item?.no_of_questions)
            }));

            let attemptedQuestionsPath = item?.answers?.map(item => item?.question_path) || ["/xyz"];
            dispatch({
                type: "GET_RESUME_SESSION_DATA", paths: { paths: attemptedQuestionsPath }, categoryData: {
                    category: categoryRec, questionCount: parseInt(item?.no_of_questions - item?.answers?.length) || item?.no_of_questions
                }
            });
            setResumeSessionrecord(userSessionRec);
            setIsResumeSessionLoading(item?.sessionLinkDestinations);
            localStorage.setItem("userSessionRec", encrypt(JSON.stringify(item?.answers || [])));
        }

    }

    const reviewQuestion = (item) => {
        if (item?.answers && item?.answers?.length > 0) {
            let questionPath = item?.answers?.map(item => item?.question_path);
            let sessionId = item?.sessionLinkDestinations.split("/").pop();
            setSessionIndexId(sessionId);
            setOpenReviewModal(true);
            if (reviewSessionData && reviewSessionData?.[sessionId] == undefined) {
                setIsSessionLoading(true);
                setReviewSessionRecord({
                    "rec": item,
                });
                dispatch({ type: "GET_REVIEW_SESSION_DATA", paths: questionPath, sessionId: sessionId });
            } else {
                setIsSessionLoading(false);
                setReviewSessionRecord({
                    "rec": item,
                    "questions": reviewSessionData[sessionId]
                });
            }

        }
    }

    useEffect(() => {
        if (reviewSessionData && reviewSessionData?.[sessionIndexId]?.length > 0) {
            setIsSessionLoading(false);
            setReviewSessionRecord({
                "rec": reviewSessionRecord?.rec || reviewSessionRecord,
                "questions": reviewSessionData[sessionIndexId]
            });
        }
    }, [reviewSessionData]);


    useEffect(() => {

        if (reviewSessionRecord && reviewSessionRecord?.questions?.length > 0) {

            setCurrentQuestion(reviewSessionRecord?.questions?.[cuurentQuestionIndex]);
        }
    }, [reviewSessionRecord, cuurentQuestionIndex]);

    useEffect(() => {

        if (sessionCategoryData && sessionCategoryData.length > 0 && sessionCategoryDataError == false &&
            Object.keys(resumeSessionrecord).length > 0) {
            dispatch({ type: "GET_USER_FLAGED_QUESTIONS", userId: getUserData().uid });
            dispatch({ type: "GET_USER_FLASH_DATA", userId: getUserData().uid });
            startSession();
            navigation("/my-session", { state: { ...resumeSessionrecord, type: "RESUME" } });
        }

        setIsResumeSessionLoading("");

    }, [sessionCategoryData,
        sessionCategoryDataError]);
    /**
     * Handles the close event of the review modal.
     * Resets the state of the review session and closes the modal.
     */
    const handleReviewModelClose = () => {
        // close the review modal
        setOpenReviewModal(false);
        // reset the review session data
        setReviewSessionRecord({});
        // reset the current question index
        setCurrentQuestionIndex(0);
        // reset the session index id
        setSessionIndexId(0);
        // reset the current question
        setCurrentQuestion({});
    }

    /**
     * Returns the correct answer for the current question.
     * If the current question has options and at least one option is correct, it returns the correct answer.
     * If the current question does not have options or no option is correct, it returns "N/A".
     * @param {string} value - The value to retrieve from the correct answer.
     * @returns {string} The correct answer or "N/A".
     */
    function correctAnswer(value) {
        // Check if the current question has options and at least one option is correct.
        if (currentQuestion?.questionChoices && currentQuestion?.questionChoices?.length > 0) {
            // Return the correct answer.
            return currentQuestion?.questionChoices?.filter(item => item?.isCorrect === true)?.[0]?.[value] || "N/A"
        } else {
            // Return "N/A" if the current question does not have options or no option is correct.
            return "N/A"
        }
    }

    /**
     * Checks if the user has answered the current question.
     * If the user has answered the question, it returns the user's answer.
     * If the user has not answered the question, it returns "N/A".
     * @returns {string} The user's answer or "N/A".
     */
    function userAnsweredQuestion() {
        // Check if the review session record exists and has answers.
        if (reviewSessionRecord?.rec && reviewSessionRecord?.rec?.answers && reviewSessionRecord?.rec?.answers?.length > 0) {
            // Find the index of the user's answer in the question choices.
            let index = reviewSessionRecord?.rec?.answers?.filter(item => item?.question_path?.split("/").filter(Boolean).pop() ==
                currentQuestion?.key)?.[0]?.selected_choice || 0;
            // Return the user's answer if it exists, otherwise return "N/A".
            return currentQuestion?.questionChoices?.[index]?.choice || "N/A";
        } else {
            // If the user has not answered the question, return "N/A".
            return "N/A"
        }
    }


    return (
        <>
            <main className="default-transition" >
                <SessionListHeader navigation={navigation} />
                <div className="row">
                    <div className="col-12 list" data-check-all="checkAll">
                        {
                            (userPreviousSessionList && Object.keys(userPreviousSessionList).length > 0) ?

                                Object.values(userPreviousSessionList).sort((a, b) => {
                                    return new Date(b?.timestamp) - new Date(a?.timestamp); // Sort by Date objects
                                })?.map((session, index) => (
                                    <div className="card d-flex flex-row mb-3" key={index}>
                                        <div className="d-flex flex-grow-1 min-width-zero">
                                            <div className="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                                                <span className="list-item-heading mb-0 truncate w-40 w-xs-100">
                                                    <h4 className='font-weight-bold'>

                                                        {getcategoryName(session?.category)}

                                                    </h4>
                                                    <div className='text-muted'>{getModeName(session?.answers?.[0]?.mode)}</div>
                                                </span>
                                                <p className="mb-0 text-muted  w-15 w-xs-100">{(session?.answers?.length || 0) + "/" + (session?.no_of_questions || 0) + " Questions"}</p>
                                                <p className="mb-0 text-muted w-15 w-xs-100">{session?.timestamp || "---"}</p>
                                                <div className="w-15 w-xs-100">
                                                    <Button
                                                        type="primary"
                                                        htmlType="button"
                                                        size="large"
                                                        loading={session?.sessionLinkDestinations == isResumeSessionLoading}
                                                        disabled={isResumeSessionLoading != ""}
                                                        onClick={() => handleBtnClick(session)}
                                                    >
                                                        {((session?.answers?.length || 0) == (session?.no_of_questions || 0)
                                                        )
                                                            ?

                                                            "Review"
                                                            :
                                                            "Resume"

                                                        }
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))

                                :
                                (userPreviousSessionList === null)
                                    ?
                                    <div className='card p-4 text-center'>
                                        <h3 className='text-muted'>No Previous Session Found !</h3>
                                    </div>
                                    :

                                    <div className='text-center m-5' >
                                        <Spin size="large" />
                                    </div>

                        }
                    </div>
                </div>
            </main>
            <Modal
                title={<h2 className='text-center font-weight-bold'>Review Session</h2>}
                loading={isSessionLoading}
                footer={null}
                zIndex={2000}
                open={openReviewModal}
                onCancel={() => handleReviewModelClose()}
                width={1000}
                style={{ maxHeight: '80vh', overflow: 'auto', }}
            >

                {/* title bar  */}
                <div className='bg-light mb-3 p-2 d-flex align-items-center justify-content-between position-sticky'
                    style={{ fontSize: '1.2rem', top: 0, zIndex: 1 }}>
                    <div>
                        <Button
                            htmlType="button"
                            disabled={cuurentQuestionIndex == 0}
                            onClick={() => setCurrentQuestionIndex(cuurentQuestionIndex - 1)}
                        >
                            <i className='simple-icon-arrow-left-circle cursor-pointer'></i>
                            Previous
                        </Button>


                    </div>
                    <div> {cuurentQuestionIndex + 1}/ {reviewSessionRecord?.questions?.length || 0} Question</div>
                    <div>
                        <Button
                            htmlType="button"

                            disabled={cuurentQuestionIndex == reviewSessionRecord?.questions?.length - 1}

                            onClick={() => setCurrentQuestionIndex(cuurentQuestionIndex + 1)}
                        >
                            Next
                            <i className='simple-icon-arrow-right-circle cursor-pointer'></i>
                        </Button>
                    </div>
                </div>
                {/* question div here   */}
                <div className='font-weight-bold mb-3' style={{ fontSize: '1rem' }}>
                    <p dangerouslySetInnerHTML={{ __html: currentQuestion?.Qbody || "Question" }}>

                    </p>
                </div>
                {/* question div end here   */}
                {/* choice div here  */}
                <div className='mb-3'>
                    {currentQuestion?.questionChoices && currentQuestion?.questionChoices?.length > 0 &&
                        currentQuestion?.questionChoices.map((item, index) => (
                            <div className='font-weight-bold ' key={index} style={{ fontSize: '1.3rem' }}>
                                <Radio.Group >
                                    <Radio value={index} disabled={true}>
                                        <span className='font-weight-bold' style={{ fontSize: '1rem', color: '#868686' }}>

                                            {item?.choice}
                                        </span>
                                    </Radio>
                                </Radio.Group>
                            </div>
                        ))}
                </div>

                {/* choice div end here */}
                {/* answer div here */}

                < div className='mb-3'>
                    <div className='font-weight-bold text-muted' style={{ fontSize: '1rem' }}>Your Answer: {userAnsweredQuestion()}</div>
                    <div className='font-weight-bold text-muted' style={{ fontSize: '1rem' }}>Correct Answer: {correctAnswer("choice")}</div>
                </div>
                <div className='mb-4'>
                    <div className='font-weight-bold text-muted' style={{ fontSize: '1rem' }}>Difficulty: {currentQuestion?.modeOfDifficulty}</div>
                </div>
                {/* answer div end here */}

                {/* expatnation div here */}
                <QuestionExplanation explanation={currentQuestion?.QExplanation || ""} />
            </Modal>
        </>
    )
}
