import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userData: null,
    isLoginLoading: false,
    loginMsg: '',
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.userData = action.payload;
            state.isLoginLoading = false;
            state.loginMsg = 'success';
        },
        loginError: (state, action) => {
            state.user = null;
            state.isLoginLoading = false;
            state.loginMsg = "error";
        },
        logout: (state) => {
            state.user = null;
        },
        clearState(state, action) {
            const name = action.payload?.name;
            const value = action.payload?.value;
            state[name] = value;
        },
        clearFullState(state) {
            state.isLoginLoading = false;
            state.loginMsg = '';
        },
    },
});

export const { login, logout, loginError, clearState, clearFullState } = authSlice.actions;

export default authSlice.reducer;