import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { login, loginError } from "../features/auth";
import { postRequestAPI, postRequestAPIWithoutHeaders } from "../../helpers/service";
import { encrypt } from "../../helpers/functions";
import { message } from "antd";




function* loginRequest({ payload }) {
    try {
        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/signInWithEmailAndPassword',
            data: payload
        });

        if (response.status === 200 && response?.data?.errorCode == 200) {
            let loginPlainObj = response.data;
            if (loginPlainObj?.userData?.stsTokenManager?.accessToken && loginPlainObj?.userInfo?.email) {

                localStorage.setItem('loggedInUser', encrypt(JSON.stringify({
                    ...loginPlainObj.userInfo,
                    "uid": loginPlainObj?.userData?.uid
                })));

                localStorage.setItem('jwt', loginPlainObj?.userData?.stsTokenManager?.accessToken);

                yield put(login(loginPlainObj));

            } else {

                throw new Error('Something went wrong while login !');

            }

        } else {

            throw new Error('Something went wrong while login !');

        }

    } catch (err) {
        yield put(loginError(err));
    }
}


function* watchAuthSagaRequests() {
    yield takeEvery('LOGIN_REQUEST', loginRequest);
}

function* AuthSaga() {
    yield watchAuthSagaRequests();
}

export default AuthSaga;