import React from 'react'

export default function QuestionExplanation({explanation}) {

    return (
        <div className='font-weight-bold mb-3 text-muted' style={{ fontSize: '1rem' }}>
            <p dangerouslySetInnerHTML={{ __html: explanation }}>
            </p>
        </div>
    )
}
