import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    setData: "",
    isSetDataError: false,

    userSavedQuestionData: [],
    userSavedQuestionDataError: false,

    gptResponse: "",
    gptResponseError: false,
};

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setData(state, action) {
            state.setData = action.payload.data;
            state.isSetDataError = action.payload.error;
        },
        userSavedQuestionList(state, action) {
            state.userSavedQuestionData = action.payload.data;
            state.userSavedQuestionDataError = action.payload.error;
        },
        gptResponse(state, action) {
            state.gptResponse = action.payload.data;
            state.gptResponseError = action.payload.error;
        },
        updateReduxByNameAndValue(state, action) {
            const name = action.payload?.name;
            const value = action.payload?.value;
            state[name] = value;
        },
        clearState(state, action) {
            const name = action.payload?.name;
            const value = action.payload?.value;
            state[name] = value;
        },
        clearFullState(state) {
            state.isLoginLoading = false;
            state.loginMsg = '';
        },
    },
});

export const { setData, clearState, clearFullState, userSavedQuestionList, gptResponse } = commonSlice.actions;

export default commonSlice.reducer;