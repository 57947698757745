import React, { useState } from 'react'
import SessionStepOne from './SessionStep/SessionStepOne'
import SessionStepTwo from './SessionStep/SessionStepTwo';

export default function NewSession() {

    const [step, setStep] = useState(1);

    const [stepData, setStepData] = useState({
        step_1: [
            {
                "mode_name": "Tutor Mode - Untimed",
                "icon": "simple-icon-graduation",
                "desc": "Learn at yout own peace. You have of checking the answer after every question.",
                "mode": 1
            },
            {
                "mode_name": "Test Mode - Untimed",
                "icon": "simple-icon-pencil",
                "desc": "Learn at yout own peace. However you can only check your answer once the test is complete.",
                "mode": 2

            },
            {
                "mode_name": "Test Mode - Timed",
                "icon": "iconsminds-over-time-2",
                "desc": "Simulate real testing environment. Clock starts once the session begins, you may still pause and resume the test at a later time.",
                "mode": 3
            },
        ],
        step_2: {
            "mode_name": "Tutor Mode - Untimed",
            "mode" : 1
        }
    })

    const handleStep = (number) => {
        setStep(number);
    }

    return (
        <>
            <main className="default-transition" >
                {
                    (step === 1)
                        ?

                        <SessionStepOne handleStep={handleStep} stepData={stepData} setStepData={setStepData} />

                        :
                        <SessionStepTwo handleStep={handleStep} stepData={stepData} setStepData={setStepData}  />
                }

            </main>

        </>
    )
}
