import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function Sidebar() {
    const navigation = useNavigate();
    const { pathname } = useLocation();
    const [active, setActive] = useState(pathname);
    const links = [

        {
            name: "New Session",
            path: "/new-session",
            icon: "simple-icon-pencil"
        },
        {
            name: "Previous Sessions",
            path: "/previous-sessions",
            icon: "iconsminds-24-hour"
        },
        {
            name: "Saved Questions",
            path: "/saved-questions",
            icon: "simple-icon-list"
        },
        {
            name: "Statistics",
            path: "/new-session1",
            icon: "iconsminds-pantone"
        },
        {
            name: "Flash Cards",
            path: "/flash-card",
            icon: "iconsminds-flash-2"
        },

    ];
    const hanldeNavigationClick = (path) => {
        setActive(path);
        navigation(path);
    }

    useEffect(() => {
        setActive(pathname);
    }, [pathname]);

    return (
        <>
            <div className="menu" style={{ opacity: 1 }}>
                <div className="main-menu default-transition">
                    <div className="scroll ps ps--active-y">
                        <ul className="list-unstyled">
                            {
                                links.map((items, index) => {
                                    return (
                                        <li key={index} className={active === items.path ? "active" : ""} onClick={() => hanldeNavigationClick(items.path)}>
                                            <Link>
                                                <i className={items.icon} />
                                                <span>{items.name}</span>
                                            </Link>
                                        </li>
                                    )
                                })
                            }

                        </ul>
                        <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                            <div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
                        </div>
                        <div className="ps__rail-y" style={{ top: 0, height: 799, right: 0 }}>
                            <div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 798 }} />
                        </div>
                    </div>
                </div>
                <div className="sub-menu default-transition">

                </div>
            </div>
        </>
    )
}
