import React from 'react'

export default function SessionStepOne({ handleStep, stepData, setStepData }) {

    const handleCardClickEvent = (value,mode) => {
        setStepData({ ...stepData, step_2: { mode_name: value, mode: mode } })
        handleStep(2)
    }
    return (
        <>
            {/* header part here  */}
            <div className='d-flex justify-content-center align-items-center'>
                <div className="row w-75">
                    <div className="col-12 text-center" >
                        <div className="mb-2">
                            <h1><b>New Session</b></h1>
                        </div>
                    </div>
                    {
                        stepData?.step_1?.map((item, index) => {
                            return (
                                <>
                                    <div className="col-12  mb-3" key={index}>
                                        <div className='row p-3  border bg-white' style={{ height: 'auto' }} >
                                            <div className="col-lg-2 col-12  d-flex align-items-center justify-content-center  p-0"> <i
                                                className={`${item?.icon} text-primary`} style={{ fontSize: '4rem' }}></i></div>
                                            <div className="col-lg-10 col-12 p-0 d-flex align-items-start justify-content-center flex-column">
                                                <h5 className=" listing-heading ellipsis cursor-pointer" onClick={() => handleCardClickEvent(item?.mode_name,item?.mode)}>
                                                    {item?.mode_name ?? ""}
                                                </h5>
                                                <p className="listing-desc text-muted ellipsis">
                                                    <div>
                                                        {item?.desc ?? ""}
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>

        </>
    )
}
