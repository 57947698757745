import { all, fork } from 'redux-saga/effects';
import AuthSaga from './auth';
import SessionSaga from './session';
import CommonSaga from './common';


// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
function* rootSaga() {
    yield all([fork(AuthSaga), fork(SessionSaga),fork(CommonSaga)]);
}

export default rootSaga;