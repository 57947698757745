import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider

      theme={{
        token: {
          colorPrimary: '#66589b',
        },
      }}

    >
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>

  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
