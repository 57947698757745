import logo from './logo.svg';
// import './App.css';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useParams,
} from 'react-router-dom';
import { RoutesList } from './routes/index';
import Headers from './components/layouts/header';
import Sidebar from './components/layouts/sidebar';
import Login from './components/auth/login';
import { isLogin, isSessionActive } from './helpers/functions';
import SessionStepThree from './components/Sessions/SessionStep/SessionStepThree';

export const Layout = ({ children }) => {
  return (
    <>
      {
        isLogin() ?
          <>
            {
              isSessionActive() ?
                <>
                  <SessionStepThree />
                </>
                :
                <>
                  <Headers />
                  <Sidebar />
                  {children}
                </>
            }
          </>
          :
          <Navigate to="/login" />
      }
    </>
  )
}
function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {
          RoutesList.map((items, index) => {
            return <Route key={index} path={items.path} element={<Layout>{items.element}</Layout>} />
          })
        }
        <Route path="/login" element={isLogin() ? <Navigate to="/" /> : <Login />} />
        <Route path="*" element={<Navigate to="/new-session" />} />
      </Route>
    )
  )
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
