//Import NPM Packages.
import axios from 'axios';
import { API_URL } from './constant';
import { accessToken } from './functions';
import { message } from 'antd';


/**
 * @function makeHttpRequest
 * @description Make an http request using axios
 * @param {function} apiCall - Axios request function
 * @returns {Promise} - The promise returned by the axios request
 * @throws {Error} - If the request fails, an error is thrown
 * @example
 * const fetchUser = () => axios.get('/user');
 * const user = await makeHttpRequest(fetchUser);
 */
function makeHttpRequest(apiCall) {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await apiCall();
            resolve(data);
        } catch (e) {
            if (e.response?.status === 401) {
                if (
                    e.response.data.msg !== '' &&
                    e.response.data.msg === 'incorrect username or password'
                ) {
                    resolve('Permission Denied');
                } else {
                    if (e.response.data.message !== 'Invalid OTP') {
                        message.error({ content: 'Permission Denied', className: 'error-class' });
                    }
                }
                resolve('Permission Denied');
            } else if (e.response?.status === 404) {
                resolve('something went wrong!');
            } else if (e.response?.status === 500) {
                let tmpMsg = e?.response?.data?.message || '';
                if (tmpMsg !== '') {
                    if (tmpMsg.includes('api_token')) {
                        // logout();
                        window.location.href = '/login';
                    }
                }
                resolve('something went wrong!');
            } else {
                resolve(e.response?.data || 'something went wrong!');
            }
        }
    });
}


/**
 * Make a GET request to the given path with the given parameters.
 * @param {string} path - The path to make the request to.
 * @param {Object} [params] - The parameters to pass to the request.
 * @returns {Promise} - The promise returned by the axios request.
 * @example
 * const user = await getRequest('/user');
 */
export function getRequest(path, params) {
    return makeHttpRequest(() =>
        axios.get(`${API_URL}${path}`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken()}`,
            },
        }),
    );
}

/**
 * Make a POST request to the given path with the given data and options.
 * @param {string} path - The path to make the request to.
 * @param {Object} data - The data to send with the request.
 * @param {Object} [options] - The options to pass to the request.
 * @returns {Promise} - The promise returned by the axios request.
 * @example
 * const user = await postRequest('/user', { name: 'John Doe' });
 */
export function postRequest(path, data, options) {
    return makeHttpRequest(() =>
        axios.post(`${API_URL}${path}`, data, {
            headers: {
                Authorization: `Bearer ${accessToken()}`,
            },
        }),
    );
}


/**
 * Make a POST request to the given path with the given data and options, without
 * sending any headers.
 * @param {string} path - The path to make the request to.
 * @param {Object} data - The data to send with the request.
 * @param {Object} [options] - The options to pass to the request.
 * @returns {Promise} - The promise returned by the axios request.
 * @example
 * const user = await postRequestWithoutHeaders('/user', { name: 'John Doe' });
 */
export function postRequestWithoutHeaders(path, data, options) {
    return makeHttpRequest(() =>
        axios.post(`${API_URL}${path}`, data,options),
    );
}

