import { Button } from 'antd'
import React from 'react'

export default function FlashCardHeader({ step, handleNavigationClick, currentCategory }) {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="mb-2">
                        {(step == 2) && <i className='simple-icon-arrow-left-circle cursor-pointer mr-2' style={{ fontSize: "1.5rem", }}

                            onClick={() => handleNavigationClick(step)}></i>}
                        <h1>Flash Cards {currentCategory && ` - ${currentCategory}`}</h1>

                        <div className="top-right-button-container d-flex align-items-end">
                            <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                                <input placeholder="Search..." />
                            </div>
                            <Button
                                type="primary"
                                htmlType="button"
                                className='mx-3'
                                size="large"
                                onClick={() => { }}
                            >
                                Review All
                            </Button>
                            <Button
                                style={{ backgroundColor: "#FFAD00" }}
                                htmlType="button"
                                size="large"
                                onClick={() => { }}
                            >
                                Export All
                            </Button>

                        </div>
                    </div>
                    <div className="separator mb-5"></div>
                </div>
            </div>
        </>
    )
}
