import React from 'react'
import { substrString } from '../../helpers/functions'

export default function FlashCard({ name, count, stepData, step, data, handleCardClick }) {
    return (
        <>

            <div className="col-md-3 col-lg-2 col-sm-4 col-6 mb-4 position-relative  mx-5" style={{ minHeight: "200px" }}>
                {
                    (step == 1)
                        ?
                        <>
                            <div className="card position-absolute w-100" style={{ zIndex: 1, top: '10px', left: '10px' }}>
                                <div className="card-body text-center p-5">
                                    <h2 className="card-text font-weight-bold">Card Name</h2>
                                    <p className="text-center card-text" style={{ fontSize: "1rem" }}>Cards</p>
                                </div>
                            </div>
                            <div className="card position-absolute w-100" style={{ zIndex: 1, top: '20px', left: '20px' }}>
                                <div className="card-body text-center p-5">
                                    <h2 className="card-text font-weight-bold">DumyText</h2>
                                    <p className="text-center card-text" style={{ fontSize: "1rem" }}>Cards</p>
                                </div>
                            </div>
                            <div className="card position-absolute w-100 cursor-pointer" style={{ zIndex: 1, top: '30px', left: '30px' }}
                                onClick={() => handleCardClick(data)}>
                                <div className="card-body text-center p-5">
                                    <h2 className="card-text font-weight-bold">{data?.[0]?.category || "Card Name"}</h2>
                                    <p className="text-center card-text" style={{ fontSize: "1rem" }}>{data?.length || "0"} Cards</p>
                                </div>
                            </div>
                        </>
                        :
                        <div className="card position-absolute w-100 cursor-pointer" style={{ zIndex: 1, top: '30px', left: '30px', height: "200px" }}
                            onClick={() => handleCardClick(data)}>
                            <div className="card-body text-center p-5">
                                <p className="text-center card-text" style={{ fontSize: "1rem" }}>{substrString(data?.front || "Card Name", 100) || "Card Name"}</p>
                            </div>
                        </div>

                }
            </div>
        </>
    )
}
