import { call, put, takeEvery } from "redux-saga/effects";
import { postRequestAPIWithoutHeaders } from "../../helpers/service";
import { previousSession } from "../features/session";
import { clearState, gptResponse, setData, userSavedQuestionList } from "../features/common";
import { message } from "antd";



function* setDataToDatabse({ path, data }) {
    try {
        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/setDataToDatabase',
            data: { pathToAddData: path, setData: data },
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });
        if (response.status === 200 && response?.data?.error == false) {

            yield put(setData({
                data: response.data.data,
                error: false
            }));

        } else {
            throw new Error('Something went wrong while set records!');
        }


    } catch (error) {
        message.error({ content: 'Something went wrong while set records!', className: 'error-class' });

        yield put(setData({
            data: "",
            error: true
        }))
    }


}


function* getUserSavedQuestionList({ paths }) {
    try {
        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/fetchDataFromDynamicPaths',
            data: { paths },
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });
        if (response.status === 200 && response?.data?.success == true) {

            yield put(userSavedQuestionList({
                data: response?.data?.data || null,
                error: false
            }));

        } else {
            throw new Error('Something went wrong while get previous session !');
        }


    } catch (error) {
        message.error({ content: 'Something went wrong while get records!', className: 'error-class' });
        yield put(userSavedQuestionList({
            data: [],
            error: true
        }))
    }
}

function* getGptAssitentResponse({ data }) {
    try {

        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/gptAssitent',
            data: data,
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });

        if (response.status === 200 && response?.data?.error == false) {
            let genratedData = "";
            for (const message of response.data.responseData.reverse()) {
                if (message?.role == "assistant") {
                    let res = message?.content[0]?.text?.value;
                    let cleanedJsonString = res.replace(/`/g, "").replace("json", "").replace(/\n/g, "");
                    genratedData = JSON.parse(cleanedJsonString || null);

                }
            }
            console.log(genratedData, "genratedData");

            yield put(gptResponse({
                data: genratedData || null,
                error: false
            }));
        } else {
            throw new Error('Something went wrong while get Gpt Assitent Response!');
        }

    } catch (e) {
        console.log(e, "error");

        message.error({ content: 'Something went wrong while get Gpt Assitent Response!', className: 'error-class' });
        yield put(gptResponse({
            data: null,
            error: true
        }));
    }
}

function* updateReduxByNameAndValue({ name, value }) {
    yield put(updateReduxByNameAndValue({
        name: name,
        value: value
    }));
}

function* clearStates({ name, value }) {

    yield put(clearState({ name, value }));
}
function* wathCommonSaga() {

    yield takeEvery("SET_DATA_TO_DATABASE", setDataToDatabse);
    yield takeEvery("GET_USER_SAVED_QUESTIONS", getUserSavedQuestionList);
    yield takeEvery("GET_GPT_ASSITENT_RESPONSE", getGptAssitentResponse);
    yield takeEvery("UPDATE_REDUX_DATA_BY_NAME_AND_VALUE", updateReduxByNameAndValue);
    yield takeEvery("CLEAR_STATE", clearStates);


}


function* CommonSaga() {
    yield wathCommonSaga();
}

export default CommonSaga