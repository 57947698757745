import { Button, Checkbox, InputNumber, message, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { encrypt, getUserData, startSession } from '../../../helpers/functions';
import { useNavigate } from 'react-router-dom';

export default function SessionStepTwo({ handleStep, stepData, setStepData }) {

    const { sessionCategoryList, sessionCategoryListError, sessionCategoryData, sessionCategoryDataError } = useSelector(state => state.SessionReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputRef = useRef();
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [step_2_data, setStep_2_data] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (sessionCategoryList.length == 0 && sessionCategoryListError === false) {
            dispatch({ type: "GET_SESSION_CATEGORY_LIST" });
        }
    }, []);

    useEffect(() => {
        if (sessionCategoryList.length == 0 && sessionCategoryListError === true) {
            message.error("Something went wrong");
        }
        if (sessionCategoryData && sessionCategoryData.length > 0 && sessionCategoryDataError === false) {
            let userSessionRec = {
                no_of_questions: inputRef.current?.value || 0,
                category: step_2_data.map((item) => ({
                    key: item?.key,
                    name: item?.title,
                })),
                timestamp: new Date().toLocaleString("en-US", {
                    timeZone: "America/Chicago",
                }),
                sessionLinkDestinations: "/q_bank_session/" + getUserData().uid + "/" + Date.now(),
            }
            dispatch({ type: "SET_DATA_TO_DATABASE", path: userSessionRec?.sessionLinkDestinations, data: userSessionRec });
            dispatch({ type: "GET_USER_FLAGED_QUESTIONS", userId: getUserData().uid });
            dispatch({ type: "GET_USER_FLASH_DATA", userId: getUserData().uid });
            userSessionRec = { ...userSessionRec, stepData: stepData?.step_2 };
            startSession();
            navigate("/my-session", { state: userSessionRec });
        }
        setLoading(false);
    }, [sessionCategoryList,
        sessionCategoryListError,
        sessionCategoryData,
        sessionCategoryDataError]);

    const handleCheckBoxClick = (e, item) => {
        if (e.target.checked) {
            // let tottalQuestion = Math.ceil(item?.totalQuestion / step_2_data.length);
            setStep_2_data([...step_2_data, { key: item?.key, totalQuestion: item?.totalQuestion, title: item?.title }]);
            setTotalQuestions(totalQuestions + item?.totalQuestion);
        }
        else {
            let newStep_2_data = step_2_data;
            newStep_2_data = newStep_2_data.filter((data) => data.key != item.key);
            setStep_2_data(newStep_2_data);
            setTotalQuestions(totalQuestions - item?.totalQuestion);

        }
    }

    const handleStartSession = () => {
        if (step_2_data.length == 0) {
            message.error("Please select Category");
            return;
        }
        if (inputRef.current?.value == null || inputRef.current?.value == 0) {
            message.error("Please enter number of questions");
            return;
        }
        setLoading(true);
        dispatch({ type: "GET_SESSION_DATA", payload: { category: step_2_data, questionCount: inputRef.current?.value } });
    }

    return (
        <>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <h1>New Session - {stepData.step_2?.mode_name ?? ""}</h1>
                <h4 className='text-muted mb-2'>Pick your Topics</h4>
                <div className='overflow-scroll '
                    style={{ maxHeight: "45vh", overflow: "auto", width: "30vw", border: "1px solid #d9d9d9", padding: "20px", borderRadius: "10px" }}
                >
                    {
                        (sessionCategoryList && sessionCategoryList.length > 0) ?
                            sessionCategoryList.map((item, index) => {
                                return (
                                    <>
                                        <div className="card d-flex flex-row mb-3 w-100 rounded-lg" key={index} >
                                            <div className="d-flex flex-grow-1 min-width-zero">
                                                <div className="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                                                    <span className="list-item-heading mb-0 truncate w-75 w-xs-100 cursor-pointer" >
                                                        {item?.title + " (" + (item?.totalQuestion || 0) + " remaining)" ?? "No Name"}
                                                    </span>
                                                    <div className="w-15 w-xs-100">
                                                        <Checkbox onChange={(e) => handleCheckBoxClick(e, item)} style={{ width: "500px" }}  ></Checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            :
                            (sessionCategoryList == null)
                                ?
                                <div className='card p-4 text-center'>
                                    <h3 className='text-muted'>No Previous Session Found !</h3>
                                </div>
                                :

                                <div className='text-center m-5' >
                                    <Spin size="large" />
                                </div>
                    }
                </div>

                <div className="row w-40 p-3">
                    <div className="col-6 d-flex align-items-center">
                        <span className="list-item-heading " >
                            Number of Questions
                        </span>
                    </div>
                    <div className="col-6">
                        <InputNumber ref={inputRef} size="large" min={0} max={totalQuestions} defaultValue={totalQuestions} />
                        <span className='list-item-heading ml-2' >
                            Out of {totalQuestions}
                        </span>
                    </div>
                </div>

                <Button
                    type="primary"
                    htmlType="submit"
                    className="my-3"
                    size="large"
                    disabled={step_2_data.length == 0}
                    loading={loading}
                    onClick={() => handleStartSession()}
                >
                    Start {stepData.step_2?.mode_name ?? ""}
                </Button>
                <Button
                    style={{ background: "#424242", color: "#fff" }}
                    htmlType="button"
                    size="large"
                    loading={loading}
                    onClick={() => handleStep(1)}
                >
                    Change Mode
                </Button>
            </div>
        </>
    )
}
