import React, { useState, useEffect } from "react";
import { formatTimeTakenByUser } from "../../helpers/functions";

const CountdownTimer = ({ initialSeconds, onTimerEnd, setTimeValue }) => {
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
                setTimeValue((prevSeconds) => prevSeconds - 1);
            }, 1000);

            // Clear the interval when the component unmounts
            return () => clearInterval(interval);
        } else if (seconds === 0) {
            // Call the onTimerEnd event when the countdown reaches 0
            onTimerEnd();
        }

    }, [seconds]);
    return (
        <span style={{ color: (seconds <= 10) ? "red" : "white" }}>{formatTimeTakenByUser(seconds) || 0}</span>
    );
};

export default CountdownTimer;
