import { Button, Modal, Radio, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import SavedQuestionHeader from './SavedQuestionHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, substrString } from '../../helpers/functions';
import QuestionExplanation from '../Sessions/Question/QuestionExplanation';

export default function SavedQuestionList() {

    const dispatch = useDispatch();

    const { userSavedQuestionData, userSavedQuestionDataError } = useSelector((state) => state.CommonReducer);

    const { userFlagedQuestionList } = useSelector((state) => state.SessionReducer);

    const [openReviewModal, setOpenReviewModal] = useState(false);
    const [reviewQuestionIndex, setReviewQuestionIndex] = useState(0);
    const [reviewQuestion, setReviewQuestion] = useState({});


    useEffect(() => {
        if (userFlagedQuestionList && userFlagedQuestionList.length == 0) {

            dispatch({ type: "GET_USER_FLAGED_QUESTIONS", userId: getUserData().uid });

        }
    }, []);

    useEffect(() => {

        if (userSavedQuestionData && userSavedQuestionData?.length == 0 && userSavedQuestionDataError == false &&
            userFlagedQuestionList && userFlagedQuestionList.length > 0) {

            dispatch({ type: "GET_USER_SAVED_QUESTIONS", paths: userFlagedQuestionList });

        }
    }, [userFlagedQuestionList]);

    const handleRevieBtnClick = (item) => {

        let reviewQuestion = userSavedQuestionData.find((data) => data?.key == item?.key);

        console.log(reviewQuestion);

        if (reviewQuestion) {
            setReviewQuestion(reviewQuestion);
            setReviewQuestionIndex(userSavedQuestionData.findIndex((data) => data?.key == item?.key));
            setOpenReviewModal(true);
        }

    }

    function correctAnswer(value) {
        if (reviewQuestion?.questionChoices && reviewQuestion?.questionChoices?.length > 0) {
            return reviewQuestion?.questionChoices?.filter(item => item?.isCorrect === true)?.[0]?.[value] || "N/A"
        } else {
            return "N/A"
        }
    }

    return (
        <>
            <main className="default-transition" >
                <SavedQuestionHeader />
                <div className="row">
                    <div className="col-12 list" data-check-all="checkAll">
                        {
                            (userSavedQuestionData && userSavedQuestionData.length > 0) ?
                                userSavedQuestionData?.map((question, index) => (
                                    <div className="card mb-3" style={{ padding: "13px 30px" }} key={index}>
                                        <div className="row align-items-center">
                                            <div className="col-md-10">
                                                <span className='font-weight-bold'
                                                    dangerouslySetInnerHTML={{ __html: substrString(question?.Qbody, 400) }} />
                                            </div>

                                            <div className="col-md-2 text-right">
                                                <Button
                                                    type="primary"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => handleRevieBtnClick(question)}
                                                >
                                                    Review
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                ))
                                :

                                (userSavedQuestionData == null)
                                    ?
                                    <div className='card p-4 text-center'>
                                        <h3 className='text-muted'>No Saved Questions Found !</h3>
                                    </div>
                                    :

                                    <div className='text-center m-5' >
                                        <Spin size="large" />
                                    </div>

                        }
                    </div>
                </div>

            </main>
            <Modal
                title={<h2 className='text-center font-weight-bold'>Review Question</h2>}
                footer={null}
                zIndex={2000}
                open={openReviewModal}
                onCancel={() => setOpenReviewModal(false)}
                width={1000}
                style={{ maxHeight: '80vh', overflow: 'auto', }}
            >
                {/* question div here   */}
                <div className='font-weight-bold mb-3' style={{ fontSize: '1rem' }}>
                    <p dangerouslySetInnerHTML={{ __html: reviewQuestion?.Qbody || "Question" }}>

                    </p>
                </div>
                {/* question div end here   */}
                {/* choice div here  */}
                <div className='mb-3'>
                    {reviewQuestion?.questionChoices && reviewQuestion?.questionChoices?.length > 0 &&
                        reviewQuestion?.questionChoices.map((item, index) => (
                            <div className='font-weight-bold' key={index} style={{ fontSize: '1.3rem' }}>
                                <Radio.Group >
                                    <Radio value={index} disabled={true}>
                                        <span>

                                            {item?.choice}
                                        </span>
                                    </Radio>
                                </Radio.Group>
                            </div>
                        ))}
                </div>

                {/* choice div end here */}
                {/* answer div here */}

                < div className='mb-3'>
                    <div className='font-weight-bold text-muted' style={{ fontSize: '1rem' }}>Correct Answer: {correctAnswer("choice")}</div>
                </div>
                <div className='mb-4'>
                    <div className='font-weight-bold text-muted' style={{ fontSize: '1rem' }}>Difficulty: {reviewQuestion?.modeOfDifficulty}</div>
                </div>
                {/* answer div end here */}

                {/* expatnation div here */}
                <QuestionExplanation explanation={reviewQuestion?.QExplanation || ""} />
            </Modal>
        </>
    )
}
