import { call, fork, put, takeEvery } from "redux-saga/effects";
import { postRequestAPIWithoutHeaders } from "../../helpers/service";
import { clearState, previousSession, reviewSessionData, sessionCategoryData, sessionCategoryList, userFlagedQuestionList, userFlashedData } from "../features/session";
import { message } from "antd";


function* userPreviousSession({ userId }) {
    try {
        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/getDataFromFireBaseDB',
            data: { path: `q_bank_session/${userId}` },
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });
        if (response.status === 200 && response?.data?.success) {

            yield put(previousSession({
                data: response.data.data,
                error: false
            }));

        } else {
            throw new Error('Something went wrong while get previous session !');
        }


    } catch (error) {
        yield put(previousSession({
            data: [],
            error: true
        }))
    }


}

function* getCategoryList() {

    try {
        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/getQBankCategoryData',
            data: { path: `/q_bank` },
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });

        if (response.status === 200 && response?.data?.error == false) {
            yield put(sessionCategoryList({
                data: response.data.data,
                error: false
            }));

        } else {
            throw new Error('Something went wrong while get previous session !');
        }

    } catch (error) {
        yield put(sessionCategoryList({
            data: [],
            error: true
        }))
    }

}

function* getUserFlagedQuestionList({ userId }) {

    try {
        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/getDataFromFireBaseDB',
            data: { path: `/userinfo/${userId}/flagged_questions` },
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });

        if (response.status === 200 && response?.data?.success == true) {
            yield put(userFlagedQuestionList({
                data: response?.data?.data || [],
                error: false
            }));

        } else {
            throw new Error('Something went wrong while get previous session !');
        }

    } catch (error) {
        yield put(userFlagedQuestionList({
            data: [],
            error: true
        }))
    }

}
function* getUserFlashData({ userId }) {

    try {
        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/getDataFromFireBaseDB',
            data: { path: `/userinfo/${userId}/flashed_data` },
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });

        if (response.status === 200 && response?.data?.success == true) {
            yield put(userFlashedData({
                data: response?.data?.data || [],
                error: false
            }));

        } else {
            throw new Error('Something went wrong while get data !');
        }

    } catch (error) {
        yield put(userFlashedData({
            data: [],
            error: true
        }))
    }

}

function* getSessionData({ payload }) {

    try {

        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/getCategoryQuestionsForKey',
            data: payload,
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });

        if (response.status === 200 && response?.data?.success == true) {
            yield put(sessionCategoryData({
                data: response?.data?.data?.questionList || [],
                error: false

            }));

        } else {
            throw new Error('Something went wrong while get previous session !');
        }

    } catch (error) {
        yield put(sessionCategoryData({
            data: [],
            error: true
        }))
    }
}
function* getResumeSessionData({ categoryData, paths }) {

    try {

        let newRecords = yield call(postRequestAPIWithoutHeaders, {
            url: '/getCategoryQuestionsForKey',
            data: categoryData,
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });

        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/fetchDataFromDynamicPaths',
            data: paths,
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });

        if (response.status === 200 && newRecords.status === 200 && response?.data?.success == true && newRecords?.data?.success == true) {
            let newData = [];
            if (response?.data?.data?.[0] !== null) {
                newData = [...response?.data?.data || [], ...newRecords?.data?.data?.questionList || []];
            } else {
                newData = [...newRecords?.data?.data?.questionList || []];
            }
            yield put(sessionCategoryData({
                data: newData || [],
                error: false

            }));

        } else {
            throw new Error('Something went wrong while get previous session !');
        }

    } catch (error) {
        console.log(error);

        yield put(sessionCategoryData({
            data: [],
            error: true
        }))
    }
}

function* getReviewSessionData({ paths, sessionId }) {
    try {

        let response = yield call(postRequestAPIWithoutHeaders, {
            url: '/fetchDataFromDynamicPaths',
            data: { paths: paths },
            options: {
                headers: {
                    'Content-Type': 'application/json',
                    'access-control-allow-origin': '*',
                    'origin': '*'
                }
            }
        });

        if (response.status === 200 && response?.data?.success == true) {
            yield put(reviewSessionData({
                data: response?.data?.data || [],
                error: false,
                sessionId: sessionId
            }));

        } else {
            throw new Error('Something went wrong while get previous session !');
        }

    } catch (error) {
        message.error('Something went wrong while get records !');
        yield put(reviewSessionData({
            data: [],
            error: true,
            sessionId: sessionId
        }))
    }
}

function* clearStates({ name, value }) {

    yield put(clearState({ name, value }));
}

function* wathSessionSaga() {
    yield takeEvery("USER_PREVIOUS_SESSION", userPreviousSession);
    yield takeEvery("GET_SESSION_CATEGORY_LIST", getCategoryList);
    yield takeEvery("GET_SESSION_DATA", getSessionData);
    yield takeEvery("GET_RESUME_SESSION_DATA", getResumeSessionData);
    yield takeEvery("GET_USER_FLAGED_QUESTIONS", getUserFlagedQuestionList);
    yield takeEvery("GET_USER_FLASH_DATA", getUserFlashData);
    yield takeEvery("GET_REVIEW_SESSION_DATA", getReviewSessionData);
    yield takeEvery("CLEAR_STATE", clearStates);
}


function* SessionSaga() {
    yield wathSessionSaga();
}

export default SessionSaga