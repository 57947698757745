import React from 'react'
import user_profile_logo from '../../assets/images/profile/user_profile.png'
import { getUserData, logout } from '../../helpers/functions';
import { Avatar, Popover } from 'antd';

export default function Headers() {
    const toggleSideBar = () => {
        if (document.body.classList.length == 0) {
            document.body.classList.add("sub-hidden", "menu-default", "main-hidden");
        } else {
            document.body.classList.remove("sub-hidden", "menu-default", "main-hidden");
        }
    }
    const onLogoutClick = () => {
        logout();
        window.location.href = '/login';
    }

    const content = (
        <div>
            <p>
                <span className="header-menu cursor-pointer" onClick={onLogoutClick} >
                    Logout
                </span>
            </p>
        </div>
    );


    return (
        <>
            <nav className="navbar fixed-top" style={{ opacity: 1 }}>
                <div className="d-flex align-items-center navbar-left">
                    <span className="menu-button d-none d-md-block" onClick={toggleSideBar} style={{ cursor: 'pointer' }}>
                        <svg className="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
                            <rect x="0.48" y="0.5" width="7" height="1" />
                            <rect x="0.48" y="7.5" width="7" height="1" />
                            <rect x="0.48" y="15.5" width="7" height="1" />
                        </svg>
                        <svg className="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
                            <rect x="1.56" y="0.5" width="16" height="1" />
                            <rect x="1.56" y="7.5" width="16" height="1" />
                            <rect x="1.56" y="15.5" width="16" height="1" />
                        </svg>
                    </span>

                    <span className="menu-button-mobile d-xs-block d-sm-block d-md-none" onClick={toggleSideBar} style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
                            <rect x="0.5" y="0.5" width="25" height="1" />
                            <rect x="0.5" y="7.5" width="25" height="1" />
                            <rect x="0.5" y="15.5" width="25" height="1" />
                        </svg>
                    </span>
                </div>

                <span className="navbar-logo">
                    <span className="logo d-none d-xs-block"></span>
                    <span className="logo-mobile d-block d-xs-none"></span>
                    <span className='' style={{ fontWeight: '900', fontSize: '20px', lineHeight: '10px', fontStyle: 'italic' }}>Q Bank</span>

                </span>

                <div className="navbar-right">
                    <div className="user d-inline-block">
                        <span className="name">{getUserData()?.name || "User"}</span>
                        <Popover placement="bottom" className='cursor-pointer' content={content} trigger="click">
                            <Avatar size="large" className="profile" style={{ width: '50px' }} src={user_profile_logo}>
                                {getUserData()?.name}
                            </Avatar>
                        </Popover>
                    </div>
                </div>
            </nav>

        </>
    )
}
