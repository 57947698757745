import { Button, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearFullState } from '../../redux/features/auth';

export default function Login() {
    const { userData, isLoginLoading, loginMsg } = useSelector(state => state.AuthReducer);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loginBtnLoading, setLoginBtnLoading] = useState(false);

    const onFinish = values => {
        setLoginBtnLoading(true);
        dispatch({ type: 'LOGIN_REQUEST', payload: values });
    };

    // for login
    useEffect(() => {
        if (loginMsg === 'success' && userData && !isLoginLoading) {
            navigate("/new-session")
        } else if (loginMsg === 'error' && !isLoginLoading) {
            message.error('Invalid email or password');
        }
        dispatch(clearFullState());
        setLoginBtnLoading(false);

    }, [loginMsg, userData, isLoginLoading, dispatch])




    return (
        <>
            <div className="fixed-background" style={{ opacity: 1 }}>
                <div className="blurEffect"></div>
            </div>
            <main className="default-transition" style={{ opacity: 1 }}>
                <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">
                            <div className="card auth-card">
                                <div className="position-relative image-side">
                                </div>
                                <div className="form-side">
                                    <a>
                                        <span className="logo-single"></span>
                                       
                                    </a>
                                    <h2 className="mb-4">Login</h2>

                                    <Form
                                        layout="vertical"
                                        onFinish={onFinish}
                                        autoComplete="off"
                                    >

                                        <Form.Item
                                            label="Email address"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter email address',
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Please enter valid email address',
                                                },
                                            ]}
                                        >
                                            <Input size="large" placeholder="Email address" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Password"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter password',
                                                }
                                            ]}
                                        >
                                            <Input.Password size="large" placeholder="••••••••••" />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="w100"
                                                size="large"
                                                loading={loginBtnLoading}
                                                disabled={loginBtnLoading}
                                            >
                                                Login
                                            </Button>
                                        </Form.Item>

                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
