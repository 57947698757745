import { SALT_KEY } from "./constant";

/**
 * Return the user's JWT token if it exists in localStorage, otherwise return
 * an empty string.
 *
 * @return {string} The user's JWT token, or an empty string.
 */
export const accessToken = () => {
    if (localStorage.getItem('jwt') !== null && localStorage.getItem('jwt')) {
        return localStorage.getItem('jwt');
    } else if (localStorage.getItem('jwtTmp') !== null && localStorage.getItem('jwtTmp')) {
        return localStorage.getItem('jwtTmp');
    } else {
        return '';
    }
};


/**
 * Checks if the user is currently logged in.
 *
 * @return {boolean} If the user is logged in, return true. Otherwise, return false.
 */
export const isLogin = () => {
    return getUserData() !== '';
};


/**
 * Logs the user out by removing the user's data and JWT token from localStorage.
 */
export const logout = () => {
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('jwt');
};

/**
 * Returns the user's data if it exists in localStorage, otherwise return an empty string.
 *
 * @return {object|string} The user's data, or an empty string.
 */
export const getUserData = () => {
    if (checkAndReturnLoginUser(true)) {
        try {
            return JSON.parse(checkAndReturnLoginUser());
        } catch (error) {
            localStorage.setItem('loggedInUser', '');
            localStorage.setItem('jwt', '');
            localStorage.setItem('jwtTmp', '');
            return '';
        }
    }
    return '';
};

/**
 * Returns the user's data if it exists in localStorage, otherwise return an empty string.
 * If 'returnBolean' is true then return boolean value.
 *
 * @param {boolean} returnBolean If true, return boolean value.
 * @return {object|string|boolean} The user's data, or an empty string.
 */
export const checkAndReturnLoginUser = (returnBolean = false) => {
    if (returnBolean) {
        return localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt');
    } else {
        return localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt')
            ? decrypt(localStorage.getItem('loggedInUser'))
            : '';
    }
};


export const encrypt1 = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ('0' + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text => text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

export const decrypt1 = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded =>
        encoded
            .match(/.{1,2}/g)
            .map(hex => parseInt(hex, 16))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
};




/**
 * Starts a session by setting a value in localStorage.
 * If the value already exists then do nothing and return true.
 * If the value does not exist then set it and return true.
 * @return {boolean} True.
 */
export const startSession = () => {
    if (localStorage.getItem('my-session') !== null && localStorage.getItem('my-session')) {
        return true;
    } else {
        localStorage.setItem('my-session', encrypt("my-session"));
        return true;
    }
};

/**
 * Checks if a session is active.
 * @return {boolean} True if the session is active, otherwise false.
 */
export const isSessionActive = () => {
    const session = localStorage.getItem('my-session');

    if (session !== null && session === encrypt("my-session")) {
        return true;
    } else {

        return false;
    }
};

/**
 * Ends the session by removing the value from localStorage.
 * @return {void} None.
 */
export const endSession = () => {
    localStorage.removeItem('my-session');
    localStorage.removeItem('userSessionRec');
    localStorage.removeItem('flashCardData');
};



// To create a cipher
export const encrypt = encrypt1(SALT_KEY);
//To decipher, you need to create a decipher and use it:
export const decrypt = decrypt1(SALT_KEY);


export function formatTimeTakenByUser(timer) {
    const hours = Math.floor(timer / 3600);
    const minutes = Math.floor((timer % 3600) / 60);
    const seconds = timer % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
}


export function substrString(text, length) {
    if (text == null || text == undefined) return text
    if (text.length > length) {
        return text.substring(0, length) + '...';
    }
    return text;
}

export function parseString(text) {
    if (text == null || text == undefined) return ""
    return text.replace(/\\n|\\t|\\r|\\b|\\f|\\\"/g, '').replace(/<[^>]*>/g, '');
}