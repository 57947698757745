import { getRequest, postRequest, postRequestWithoutHeaders } from "./apiCall";

export function postRequestAPI({ url = '', data = {} }) {
    return postRequest(url, data);
}
export function postRequestAPIWithoutHeaders({ url = '', data = {}, options = {} }) {
    return postRequestWithoutHeaders(url, data,options);
}


export function getRequestAPI({ url = '', params = {} }) {
    return getRequest(url, params);
}