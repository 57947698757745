import { Button } from 'antd'
import React from 'react'

export default function SessionListHeader({ navigation }) {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="mb-2">
                        <h1>Previous Session List</h1>
                        <div className="top-right-button-container">
                            <Button
                                type="primary"
                                htmlType="button"
                                size="large"
                                onClick={() => navigation("/new-session")}
                            >
                                Start New Session +
                            </Button>
                        </div>
                    </div>
                    <div className="separator mb-5"></div>
                </div>
            </div>
        </>
    )
}
