import React from 'react'

export default function SavedQuestionHeader({ navigation }) {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="mb-2">
                        <h1>Saved Question List</h1>
                    </div>
                    <div className="separator mb-5"></div>
                </div>
            </div>
        </>
    )
}
