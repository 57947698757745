import FlashCardList from '../components/FlashCard/FlashCardList';
import SavedQuestionList from '../components/SavedQuestions/SavedQuestionList';
import NewSession from '../components/Sessions/NewSession';
import SessionList from '../components/Sessions/SessionList';
import SessionStepThree from '../components/Sessions/SessionStep/SessionStepThree';


export const RoutesList = [
    {
        path: '/previous-sessions',
        element: <SessionList />,
        name: 'previous-sessions'
    },
    {
        path: '/new-session',
        element: <NewSession />,
        name: 'new-session'
    },
    {
        path: '/saved-questions',
        element: <SavedQuestionList />,
        name: 'saved-questions'
    },
    {
        path: '/flash-card',
        element: <FlashCardList />,
        name: 'saved-questions'
    },
    {
        path: '/my-session',
        element: <SessionStepThree />,
        name: 'my-session'
    },

];
