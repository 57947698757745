import { combineReducers } from 'redux';
import AuthReducer from './auth';
import SessionReducer from './session';
import CommonReducer from './common';


export default combineReducers({
    AuthReducer,
    SessionReducer,
    CommonReducer
});