import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userPreviousSessionList: [],
    userPreviousSessionListError: false,

    sessionCategoryList: [],
    sessionCategoryListError: false,

    sessionCategoryData: [],
    sessionCategoryDataError: false,

    userFlagedQuestionList: [],
    userFlagedQuestionListError: false,

    reviewSessionData: [],
    reviewSessionDataError: false,

    userFlashedData: null,
    userFlashedDataError: false,
};

export const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        previousSession(state, action) {
            state.userPreviousSessionList = action.payload.data;
            state.userPreviousSessionListError = action.payload.error;
        },
        sessionCategoryList(state, action) {
            state.sessionCategoryList = action.payload.data;
            state.sessionCategoryListError = action.payload.error;
        },
        userFlagedQuestionList(state, action) {
            state.userFlagedQuestionList = action.payload.data;
            state.userFlagedQuestionListError = action.payload.error;
        },
        userFlashedData(state, action) {
            state.userFlashedData = action.payload.data;
            state.userFlashedDataError = action.payload.error;
        },
        sessionCategoryData(state, action) {
            state.sessionCategoryData = action.payload.data;
            state.sessionCategoryDataError = action.payload.error;
        },
        reviewSessionData(state, action) {
            let index = action.payload.sessionId
            state.reviewSessionData[index] = action.payload.data
            state.sessionCategoryDataError = action.payload.error;
        },
        clearState(state, action) {
            const name = action.payload?.name;
            const value = action.payload?.value;
            state[name] = value;
        },
        clearFullState(state) {
            state.isLoginLoading = false;
            state.loginMsg = '';
        },
    },
});

export const { clearState, clearFullState, previousSession, sessionCategoryList, sessionCategoryData, userFlagedQuestionList,reviewSessionData, userFlashedData } = sessionSlice.actions;

export default sessionSlice.reducer;