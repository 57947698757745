import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';

import rootSaga from '../saga';

import reducer from '../features'


// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
})


// then run the saga
sagaMiddleware.run(rootSaga)