export const FLASH_CARD_INSTRUCTIONS_WITHOUT_KEYWORDS = `You are a helpful assistant that generates json flash cards based on a board style review question.
Example input:

A 60-year-old patient with stage 4 chronic kidney disease (CKD) presents with anemia. Initial laboratory results reveal a hemoglobin level of 9.8 g/dL, serum iron of 80 mcg/dL, ferritin of 120 ng/mL, and transferrin saturation of 25%. What is the most appropriate initial treatment for anemia in this patient?
A) Erythropoiesis-stimulating agents (ESAs)
B) Oral iron supplementation
C) Intravenous iron supplementation
D) Folic acid supplementation
E) Blood transfusion
Answer:
A) Erythropoiesis-stimulating agents (ESAs)
Explanation:
In this patient with CKD and anemia, the most appropriate initial treatment is erythropoiesis-stimulating agents (ESAs). The normal iron stores (ferritin and transferrin saturation) suggest that the anemia is primarily related to inadequate erythropoietin production, a common feature in CKD-related anemia. Iron stores need to be normal for ESAs to be effective in increasing hgb. Goal hgb should be 11-12 g/dL to minimize risk of adverse cardiovascular events.
Rationale:
Normal Iron Stores: The patient's ferritin and transferrin saturation are within the normal range, suggesting that iron deficiency is not the primary cause of anemia.
CKD-Related Anemia: In CKD, anemia often results from impaired erythropoietin production. ESAs, such as erythropoietin or darbepoetin alfa, are indicated in patients with CKD and anemia when the hemoglobin level is below target.
Avoiding Iron Overload: Since the patient has normal iron stores, the use of additional iron supplementation may not be necessary and could lead to iron overload

generate two quick and concise flash cards that are most relevant to this question. return in json with front / back key value pairs

Example output:
[
  {
    "front": "What is the most appropriate initial treatment for anemia in a patient with stage 4 CKD and normal iron stores?",
    "back": "Erythropoiesis-stimulating agents (ESAs)."
  }
]`;


export const FLASH_CARD_INSTRUCTIONS_WITH_KEYWORDS = `You are a helpful assistant that generates a flashcard based on a keyword: 
Example input:
Hematology Section
A 60-year-old patient with stage 4 chronic kidney disease (CKD) presents with anemia. Initial laboratory results reveal a hemoglobin level of 9.8 g/dL, serum iron of 80 mcg/dL, ferritin of 120 ng/mL, and transferrin saturation of 25%. What is the most appropriate initial treatment for anemia in this patient?
A) Erythropoiesis-stimulating agents (ESAs)
B) Oral iron supplementation
C) Intravenous iron supplementation
D) Folic acid supplementation
E) Blood transfusion
Answer:
A) Erythropoiesis-stimulating agents (ESAs)
Explanation:
In this patient with CKD and anemia, the most appropriate initial treatment is erythropoiesis-stimulating agents (ESAs). The normal iron stores (ferritin and transferrin saturation) suggest that the anemia is primarily related to inadequate erythropoietin production, a common feature in CKD-related anemia. Iron stores need to be normal for ESAs to be effective in increasing hgb. Goal hgb should be 11-12 g/dL to minimize risk of adverse cardiovascular events.
Rationale:
Normal Iron Stores: The patient's ferritin and transferrin saturation are within the normal range, suggesting that iron deficiency is not the primary cause of anemia.
CKD-Related Anemia: In CKD, anemia often results from impaired erythropoietin production. ESAs, such as erythropoietin or darbepoetin alfa, are indicated in patients with CKD and anemia when the hemoglobin level is below target.
Avoiding Iron Overload: Since the patient has normal iron stores, the use of additional iron supplementation may not be necessary and could lead to iron overload

Keyword: ESAs

generate one quick and concise flash cards on the topic of ESAs. return in json with front / back key value pairs

example output:
[
{
  "front": "What is the primary indication for erythropoiesis-stimulating agents (ESAs) in patients with chronic kidney disease?",
  "back": "To treat anemia by stimulating red blood cell production due to inadequate erythropoietin production."
}
]`;