import React, { useEffect, useState } from 'react'
import FlashCardHeader from './FlashCardHeader'
import FlashCard from './FlashCard';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Spin } from 'antd';
import { getUserData } from '../../helpers/functions';

export default function FlashCardList() {

    const { userFlashedData } = useSelector(state => state.SessionReducer)
    const dispatch = useDispatch()
    const [flashCardModal, setFlashCardModal] = useState(false)
    const [flashCardData, setFlashCardData] = useState({})
    const [flashCardCurrentIndex, setFlashCardCurrentIndex] = useState("front")

    const [step, setStep] = useState(1);
    const [stepData, setStepData] = useState({})
    const [currentCategory, setCurrentCategory] = useState(null);

    useEffect(() => {
        if (userFlashedData == null || userFlashedData?.length == 0) {
            dispatch({ type: "GET_USER_FLASH_DATA", userId: getUserData().uid });
        }

    }, [])

    useEffect(() => {
        if (userFlashedData?.length > 0) {
            if (step == 1) {

                setStepData(Object.groupBy(userFlashedData, ({ category }) => category));

            } else if (step == 2) {
                setStepData(Object.groupBy(userFlashedData, ({ category }) => category)?.[currentCategory] || {});
            }
        }

    }, [step, userFlashedData])


    const handleCardClick = (data) => {
        if (step == 1) {
            setStep(2)
            setCurrentCategory(data?.[0]?.category)
        } else if (step == 2) {
            setFlashCardModal(true)
            setFlashCardData(data)
        }
    }

    const handleNavigationClick = (step) => {
        switch (step) {
            case 2:
                setStep(1)
                setCurrentCategory(null)
                break;
            default:
                break;
        }
    }
    return (
        <>
            <main className="default-transition" >
                <FlashCardHeader step={step} handleNavigationClick={handleNavigationClick} currentCategory={currentCategory} />
                {
                    (userFlashedData?.length > 0 && stepData && Object.keys(stepData).length > 0) ?
                        <div className="row mb-4">
                            {
                                Object.values(stepData).map((item, index) => {
                                    return <FlashCard data={item} key={index} handleCardClick={handleCardClick} stepData={stepData} step={step} />
                                })
                            }
                        </div>
                        :
                        (userFlashedData?.length == 0)
                            ?
                            <div className='card p-4 text-center'>
                                <h3 className='text-muted'>No Flash Card Found !</h3>
                            </div>
                            :
                            <div className='text-center m-5' >
                                <Spin size="large" />
                            </div>

                }
            </main>
            <Modal
                title={<h2 className='text-center font-weight-bold mb-3'>Card Data</h2>}
                open={flashCardModal}
                zIndex={10000}
                onCancel={() => (setFlashCardModal(false), setFlashCardCurrentIndex("front"))}
                footer={null}
            >
                <div className='card d-flex align-items-center justify-content-center font-weight-bold text-center p-2'
                    style={{ height: '200px' }}>
                    {flashCardData?.[flashCardCurrentIndex]}
                </div>
                <div className="row text-center">
                    <div className="col-6">
                        <Button className='my-3 px-5'
                            size="large"
                            style={{ background: "#FFAD00" }}
                            onClick={() => { setFlashCardCurrentIndex((flashCardCurrentIndex == "front" ? "back" : "front")) }}
                        >
                            <i className='iconsminds-repeat-2 font-weight-bold'></i>
                            Flip
                        </Button>

                    </div>

                    <div className="col-6">
                        <Button className='my-3 px-5'
                            size="large"
                            type='primary'
                        >
                            <i className='simple-icon-pencil'></i>
                            Edit
                        </Button>

                    </div>

                </div>

            </Modal>
        </>
    )
}
