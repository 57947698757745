import React from 'react'

import tubeImage from '../../../assets/images/Vector.png';
import exitsImg from '../../../assets/images/exitImg.png';
import { Popconfirm } from 'antd';
import CountdownTimer from '../../common/CountdownTimer';
import { decrypt } from '../../../helpers/functions';

export default function StepThreeHeader({ handleExitSession, currentQuestionIndex, tottalQuestions, flageQuestion, setFlageQuestion, SessionRec, onTimerEnd, setCurrentTime }) {

    const setTimer = () => {
        if (SessionRec?.type == "RESUME") {
            let userSessionRec = localStorage.getItem("userSessionRec") || [];
            if (userSessionRec.length > 0) {
                userSessionRec = JSON.parse(decrypt(userSessionRec));
            }
            if (userSessionRec?.length > 0) {
                return userSessionRec[userSessionRec?.length - 1]?.timer;
            } else {
                return tottalQuestions * 120;
            }
        } else {
            return tottalQuestions * 120;
        }
    }

    return (
        <div className='d-flex justify-content-between align-items-center p-2 bg-primary text-white font-weight-bold position-sticky'
            style={{ top: 0, zIndex: 1 }} >
            <div>
                <Popconfirm
                    title="Are you sure to leave session?"
                    description="changes you made will be saved."
                    onConfirm={handleExitSession}
                    okText="Yes"
                    cancelText="No"
                >
                    <span className='cursor-pointer'>
                        Exit Session &nbsp;  <img src={exitsImg} alt="Tube" />
                    </span>
                </Popconfirm>
            </div>
            <div >
                Question {currentQuestionIndex + 1} / {tottalQuestions}
            </div>
            <div className='d-flex align-items-center justify-content-center mx-4'>

                {
                    (SessionRec?.stepData?.mode == 3) &&

                    <span>Time remaining : {
                        <CountdownTimer
                            initialSeconds={setTimer()}
                            onTimerEnd={() => { onTimerEnd() }}
                            setTimeValue={(time) => setCurrentTime(time)}
                        />}
                    </span>

                }
                <span className='mx-4 cursor-pointer' >
                    <img src={tubeImage} alt="Tube" />
                </span>
                <span onClick={() => setFlageQuestion(!flageQuestion)} className='cursor-pointer'>
                    <i className='simple-icon-flag' style={{ fontSize: '1.2rem', color: (flageQuestion ? 'red' : 'white'), fontWeight: 'bold' }} ></i>
                </span>
            </div>

        </div>

    )
}
